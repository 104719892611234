<template>
  <div class="container">
    <div class="card mt-4">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <a href="#tabs-marketing" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
              ارسال پیام یا ایمیل
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a href="#tabs-primary-messages" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">
              تاریخچه پیام‌ها
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <!-- Existing Marketing Page Tab -->
          <div class="tab-pane fade active show" id="tabs-marketing" role="tabpanel">
            



<div class="row">
  <!-- Left side - Selection of Webinars and Products -->
  <div class="col-lg-6">
    <h2>انتخاب گروه</h2>
    <div class="d-flex">
      <div class="w-50 p-2">
        <label>انتخاب وبینار</label>
        <multiselect
          v-model="selectedWebinars"
          :options="filteredWebinars"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="انتخاب وبینار"
          track-by="id"
          :searchable="true"
          :custom-label="webinarLabel"
          @input="fetchAttendees"
        >
          <template #selection="{ values, isOpen }">
            <span v-if="values.length && !isOpen" class="multiselect__single">
              {{ values.length }} وبینار انتخاب شده
            </span>
          </template>
        </multiselect>
      </div>
      <div class="w-50 p-2">
        <label>انتخاب محصول</label>
        <multiselect
          v-model="selectedProducts"
          :options="filteredProducts"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="انتخاب محصول"
          label="title"
          track-by="id"
          :searchable="true"
          @input="fetchAttendees"
        >
          <template #selection="{ values, isOpen }">
            <span v-if="values.length && !isOpen" class="multiselect__single">
              {{ values.length }} محصول انتخاب شده
            </span>
          </template>
        </multiselect>
      </div>
    </div>

    <!-- Members Table -->
    <h3>لیست اعضا</h3>
    
    <!-- Search Bar for Members Table -->
    <input
      type="text"
      v-model="memberSearch"
      class="form-control mb-2"
      placeholder="جستجوی اعضا"
    />

    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th><input type="checkbox" @change="toggleSelectAll($event)" /></th>
            <th>#</th>
            <th>نام</th>
            <th>ایمیل</th>
            <th>منبع</th>
            <th>اطلاعات بیشتر</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(member, index) in filteredMembersBySearch" :key="member.email">
            <td><input type="checkbox" :checked="isSelected(member)" @change="toggleMemberSelection(member)" /></td>
            <td>{{ index + 1 }}</td>
            <td>{{ member.name }}</td>
            <td>{{ member.email }}</td>
            <td>{{ member.source }}</td>
            <td>
              <button class="btn btn-sm btn-info" @click="showMoreInfo(member)">
                بیشتر
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br><br>


    <h2>ارسال پیام</h2>
    <!-- Subject Input Field -->
    <input type="text" v-model="emailSubject" class="form-control mb-3" placeholder="موضوع ایمیل" />
    <!-- CKEditor -->
            <!-- Add this button somewhere near CKEditor in your template -->
<button class="btn btn-primary mt-2" style="font-size:15px;margin-bottom:10px;padding:8px 18px;font-weight:normal;font-family:IRANSans!important" @click="insertNameTag">قراردادن نام کاربر در متن</button>

    <ckeditor
ref="ckeditor"
:editor="editor"
v-model="messageContent"
:config="editorConfig"
@ready="onEditorReady"
></ckeditor>

<!-- Attach Media Files Section -->
<div class="row">
  <div class="col-lg-6">
    <h2>ارسال فایل‌ها با پیام تلگرام</h2>

    <!-- Photo -->
    <div class="mb-3">
      <label for="photo" class="form-label">ارسال عکس</label>
      <input
        type="file"
        id="photo"
        class="form-control"
        @change="onFileChange($event, 'photo')"
        accept="image/*"
      />
      <input
        type="text"
        v-model="mediaCaptions.photo"
        class="form-control mt-2"
        placeholder="کپشن عکس (اختیاری)"
      />
      <button class="btn btn-sm btn-danger mt-2" @click="clearFile('photo')">حذف عکس</button>
    </div>

    <!-- Video -->
    <div class="mb-3">
      <label for="video" class="form-label">ارسال ویدئو</label>
      <input
        type="file"
        id="video"
        class="form-control"
        @change="onFileChange($event, 'video')"
        accept="video/*"
      />
      <input
        type="text"
        v-model="mediaCaptions.video"
        class="form-control mt-2"
        placeholder="کپشن ویدئو (اختیاری)"
      />
      <button class="btn btn-sm btn-danger mt-2" @click="clearFile('video')">حذف ویدئو</button>
    </div>

    <!-- Audio -->
    <div class="mb-3">
      <label for="audio" class="form-label">ارسال صوت</label>
      <input
        type="file"
        id="audio"
        class="form-control"
        @change="onFileChange($event, 'audio')"
        accept="audio/*"
      />
      <input
        type="text"
        v-model="mediaCaptions.audio"
        class="form-control mt-2"
        placeholder="کپشن صوت (اختیاری)"
      />
      <button class="btn btn-sm btn-danger mt-2" @click="clearFile('audio')">حذف صوت</button>
    </div>

    <!-- PDF -->
    <div class="mb-3">
      <label for="pdf" class="form-label">ارسال PDF</label>
      <input
        type="file"
        id="pdf"
        class="form-control"
        @change="onFileChange($event, 'pdf')"
        accept=".pdf"
      />
      <input
        type="text"
        v-model="mediaCaptions.pdf"
        class="form-control mt-2"
        placeholder="کپشن PDF (اختیاری)"
      />
      <button class="btn btn-sm btn-danger mt-2" @click="clearFile('pdf')">حذف PDF</button>
    </div>
  </div>
</div>

            <!-- Send Buttons -->
            <div class="d-flex justify-content-between mt-3">
              <button class="btn btn-primary" @click="sendMessages">ارسال اتوماتیک</button>
              <div>
                <button class="btn btn-primary" @click="sendTelegramMessagesWithMedia">ارسال پیام تلگرام</button>
                <button class="btn btn-primary" @click="sendEmails">ارسال ایمیل</button>
              </div>
            </div>

    <br><br><br><br><br>
  </div>

  <!-- Right side - CKEditor and Final Table -->
  <div class="col-lg-6">
    
    <!-- Final Table -->
    <h3>اعضای نهایی</h3>
    <table class="table">
      <thead>
        <tr>
          <th>ردیف</th>
          <th>نام</th>
          <th>ایمیل</th>
          <th>منبع</th>
          <th>عملیات</th>
          <th><button class="btn btn-danger btn-sm mb-2" style="margin-bottom:0!important" @click="removeAllFromFinalTable">حذف همه</button></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(member, index) in finalMembers" :key="member.email">
          <td>{{ index + 1 }}</td>
          <td>{{ member.name }}</td>
          <td>{{ member.email }}</td>
          <td>{{ member.source }}</td>
          <td>{{ member.emailStatus || '' }}</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="removeFromFinalTable(index)">
              حذف
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Modal for More Information -->
<div class="modal fade" id="moreInfoModal" tabindex="-1" role="dialog" aria-labelledby="moreInfoModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="moreInfoModalLabel">اطلاعات بیشتر</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5>بقیه اطلاعات</h5>
        <table class="table table-striped">
          <tbody>
            <tr><th>شغل فعلی</th><td>{{ selectedMember.CurrentJob }}</td></tr>
            <tr><th>رشته تحصیلی</th><td>{{ selectedMember.StudyField }}</td></tr>
            <tr><th>مقطع تحصیلی</th><td>{{ selectedMember.EducationLevel }}</td></tr>
            <tr><th>استان/کشور محل سکونت</th><td>{{ selectedMember.CountryStateResidence }}</td></tr>
          </tbody>
        </table>
        <hr>
        <h5>تراکنش‌ها</h5>
        <div v-if="userTransactions.length > 0" class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr><th>نام محصول</th><th>تاریخ</th></tr>
            </thead>
            <tbody>
              <tr v-for="transaction in userTransactions" :key="transaction.id">
                <td>{{ transaction.product_name }}</td>
                <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>تراکنشی یافت نشد</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">بستن</button>
      </div>
    </div>
  </div>
</div>
          </div>

          <!-- New Primary Messages History Tab -->
          <div class="tab-pane fade" id="tabs-primary-messages" role="tabpanel">
  <div class="mt-4">
    <h3>تاریخچه پیام‌های ارسال‌شده</h3>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>شناسه</th>
          <th>ایمیل</th>
          <th>عنوان</th>
          <th>نویسنده</th>
          <th>تاریخ</th>
          <th>زمان</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="message in primaryMessages"
          :key="message.id"
          data-bs-toggle="tooltip"
          :title="message.content"
        >
          <td>{{ message.id }}</td>
          <td>{{ message.email }}</td>
          <td>{{ message.title }}</td>
          <td>{{ message.author }}</td>
          <td>{{ formatShamsiDate(message.created_at).date }}</td>
          <td>{{ formatShamsiDate(message.created_at).time }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'vue-multiselect';
import { Modal } from 'bootstrap';
import { Tooltip } from 'bootstrap';
import { htmlToText } from 'html-to-text';

export default {
  components: { Multiselect },
  data() {
    return {
      webinars: [],
      products: [],
      
      mediaFiles: {
        photo: null,
        video: null,
        audio: null,
        pdf: null,
      },
      mediaCaptions: {
        photo: '',
        video: '',
        audio: '',
        pdf: '',
      },
      members: [],
      messages: [], // All messages data
      primaryMessages: [], // Only primary messages for the new tab
      selectedWebinars: [],
      selectedProducts: [],
      finalMembers: [],
      memberSearch: '', // Search query for members table
      messageContent: '',
      emailSubject: '',
      editor: ClassicEditor,
      editorInstance: null, // Store CKEditor instance
      editorConfig: {
        language: 'ar',
        contentsLangDirection: 'rtl',
        alignment: {
          options: ['right', 'left']
        }
      },
      selectedMember: {}, // To store selected member details
      userTransactions: [], // To store transactions of a user
    };
  },
  computed: {
    filteredWebinars() {
      return this.webinars;
    },
    filteredProducts() {
      return this.products;
    },
    filteredMembers() {
      if (this.selectedWebinars.length === 0 && this.selectedProducts.length === 0) {
        return this.members;
      }

      const attendeesEmails = new Set();

      this.selectedWebinars.forEach(webinar => {
        webinar.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      this.selectedProducts.forEach(product => {
        product.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      return this.members.filter(member => attendeesEmails.has(member.email));
    },
    filteredMembersBySearch() {
      if (!this.memberSearch || !this.memberSearch.trim()) {
        return this.filteredMembers;
      }

      const searchTerm = this.memberSearch.trim().toLowerCase();

      return this.filteredMembers.filter(member => {
        const name = member.name ? member.name.toLowerCase() : '';
        const email = member.email ? member.email.toLowerCase() : '';

        return name.includes(searchTerm) || email.includes(searchTerm);
      });
    }
  },
  methods: {
   
    clearFile(type) {
      // Clear the file and its caption
      this.mediaFiles[type] = null;
      this.mediaCaptions[type] = '';

      // Reset the file input element to allow re-upload
      const inputElement = document.getElementById(type);
      if (inputElement) {
        inputElement.value = null;
      }
    },
    
    fetchMessages() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_messages.php', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.messages = response.data;
          this.primaryMessages = this.messages.filter(
          (message) => message.primary_message === '1' && message.author === 'ادمین'
        );

          // Ensure tooltips are initialized only after the DOM is updated
          this.$nextTick(() => this.initializeTooltips());
        })
        .catch((error) => {
          console.error('Error fetching messages:', error);
        });
    },
    formatShamsiDate(dateTimeString) {
      const [time, date] = dateTimeString.split(' - ');
      return { date, time };
    },
    initializeTooltips() {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl, {
          trigger: 'hover',
          placement: 'bottom',  // Position the tooltip at the bottom
          animation: true,
          customClass: 'custom-tooltip-width'  // Apply custom width class
        });
      });
    },
  onEditorReady(editor) {
    this.editorInstance = editor; // Store the CKEditor instance when ready
  },

  insertNameTag() {
    // Check if the editorInstance is ready
    if (this.editorInstance) {
      const model = this.editorInstance.model;
      const selection = model.document.selection;
      const insertPosition = selection.getFirstPosition();

      // Insert "@name" at the current selection
      model.change(writer => {
        writer.insertText('@name', insertPosition);
      });
    } else {
      console.error('Editor instance is not ready.');
    }
  },
    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i]; // Adjust the index [0] or [1] based on which part you need
      }
      return '';
    },
    webinarLabel(webinar) {
      return `${webinar.title} - ${webinar.nth_course}`;
    },
    removeAllFromFinalTable() {
      this.finalMembers = [];
    },
    fetchWebinars() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.webinars = response.data.webinars || [];
        })
        .catch(error => {
          console.error('Error fetching webinars:', error);
          window.location.href = '/logout';
        });
    },
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_products.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.products = response.data.webinars;
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    },
    fetchMembers() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/robot_users_info.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.members = response.data.map(user => ({
            name: user.FirstNameLastName,
            email: user.EMailAddress,
            source: user.Source === 'Robot' ? 'ربات' : 'وبسایت',
            CurrentJob: user.CurrentJob,
            StudyField: user.StudyField,
            EducationLevel: user.EducationLevel,
            CountryStateResidence: user.CountryStateResidence,
            emailStatus: '' // Initialize the email status
          }));
        })
        .catch(error => {
          console.error('Error fetching members:', error);
        });
    },
    fetchAttendees() {
      this.fetchMembers();
    },
    toggleMemberSelection(member) {
      const index = this.finalMembers.findIndex(finalMember => finalMember.email === member.email);
      if (index === -1) {
        this.finalMembers.push({ ...member, emailStatus: '' });
      } else {
        this.finalMembers.splice(index, 1);
      }
    },
    removeFromFinalTable(index) {
      this.finalMembers.splice(index, 1);
    },
    

    sendMessages() {
    this.finalMembers.forEach((member) => {
      member.emailStatus =
        member.source === "ربات" ? "در حال ارسال پیام تلگرام" : "در حال ارسال ایمیل";

      if (member.source === "ربات") {
        this.uploadMediaAndSendMessage(member); // Send Telegram message with or without photo
      } else {
        this.sendEmail(member); // Send email
      }
    });
  },

  sendEmails() {
    this.finalMembers.forEach((member) => {
      member.emailStatus = "در حال ارسال ایمیل";
      this.sendEmail(member);
    });
  },

  onFileChange(event, type) {
      const file = event.target.files[0];
      if (file) {
        this.mediaFiles[type] = file;
      } else {
        this.mediaFiles[type] = null;
      }
    },
    sendTelegramMessagesWithMedia() {
      this.finalMembers.forEach((member) => {
        if (member.source === 'ربات') {
          this.uploadMediaAndSendMessage(member);
        }
      });
    },
    uploadMediaAndSendMessage(member) {
      const formData = new FormData();
      formData.append('email', member.email);

      
    // Replace @name with the actual member's name
    const personalizedMessage = this.messageContent.replace(/@name/g, member.name);

const telegramMessage = `
  ${htmlToText(personalizedMessage, {
    wordwrap: 130,
    uppercaseHeadings: false,
    singleNewLineParagraphs: true,
    tags: {
      a: { format: "inline" },
      p: { format: "block" },
    },
  })}
`;

formData.append("text", telegramMessage);
      Object.entries(this.mediaFiles).forEach(([key, file]) => {
        if (file) {
          formData.append(key, file);
          formData.append(`${key}Caption`, this.mediaCaptions[key]);
        }
      });

      axios
        .post('https://dashboard.ordmenpodcast.com/v/send_telegram_with_media.php', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        })
        .then((response) => {
          console.log(response)
          member.emailStatus = response.data.success ? 'پیام تلگرام ارسال شد' : 'پیام ارسال نشد';
        })
        .catch(() => {
          member.emailStatus = 'پیام ارسال نشد';
        });
    },
  sendEmail(member) {
    const token = localStorage.getItem("jwt");

    // Replace @name with the actual member's name
    const personalizedMessage = this.messageContent.replace(/@name/g, member.name);

    const emailContent = `
      <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
      ${personalizedMessage}
      </div>
    `;

    axios
      .post(
        "https://dashboard.ordmenpodcast.com/v/send_email.php",
        {
          name: member.name,
          email: member.email,
          subject: this.emailSubject,
          content: emailContent,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        member.emailStatus = response.data.success
          ? "ایمیل ارسال شد"
          : "ایمیل ارسال نشد";
      })
      .catch(() => {
        member.emailStatus = "ایمیل ارسال نشد";
      });
  },

    toggleSelectAll(event) {
      if (event.target.checked) {
        this.filteredMembersBySearch.forEach(member => {
          if (!this.finalMembers.some(finalMember => finalMember.email === member.email)) {
            this.finalMembers.push({ ...member, emailStatus: '' });
          }
        });
      } else {
        this.finalMembers = this.finalMembers.filter(
          finalMember => !this.filteredMembersBySearch.some(member => member.email === finalMember.email)
        );
      }
    },
    isSelected(member) {
      return this.finalMembers.some(finalMember => finalMember.email === member.email);
    },
    showMoreInfo(member) {
      this.selectedMember = member;
      this.showUserTransactions(member.email); // Fetch transactions before showing the modal
      const modalElement = document.getElementById('moreInfoModal');
      const moreInfoModal = new Modal(modalElement);
      moreInfoModal.show();
    },
    showUserTransactions(email) {
      const token = localStorage.getItem('jwt');
      axios.post('https://dashboard.ordmenpodcast.com/v/list_user_transactions.php', {
        email: email
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (Array.isArray(response.data)) {
          this.userTransactions = response.data.filter(transaction => transaction.product_name);
        } else {
          console.error('Expected an array but received:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching user transactions:', error);
      });
    }
  },
  mounted() {
    this.fetchWebinars();
    this.fetchProducts();
    this.fetchMessages(); // Fetch messages when component is mounted
    this.fetchMembers(); // Fetch all members when component is mounted
    this.initializeTooltips();
 
  }
};
</script>



<style>


body{
  overflow-x:hidden;
}
.container {
  width: 100% !important;
  max-width: 100% !important;
  font-size: 12.5px !important;
  margin-top: 20px;
}


/* Table container for "لیست اعضا" to enable scrolling */
.table-container {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* 5" Displays (typically around 640x360) */
@media screen and (max-width: 640px) and (max-height: 360px) {
  .table-container {
    max-height: 100px;
  }
}

/* 7" Displays (typically around 800x480) */
@media screen and (max-width: 800px) and (max-height: 480px) {
  .table-container {
    max-height: 150px;
  }
}

/* 9" Displays (typically around 1024x600) */
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .table-container {
    max-height: 200px;
  }
}

/* 10" Displays (typically around 1280x720 or 1280x800) */
@media screen and (max-width: 1280px) and (max-height: 800px) {
  .table-container {
    max-height: 220px;
  }
}

/* 13" Displays - HD (1366x768) */
@media screen and (min-width: 1366px) and (max-width: 1366px) and (max-height: 768px) {
  .table-container {
    max-height: 250px;
  }
}

/* 15" Displays - Full HD (1920x1080) */
@media screen and (min-width: 1920px) and (max-width: 1920px) and (max-height: 1080px) {
  .table-container {
    max-height: 300px;
  }
}

/* 20" Displays - 2K (2560x1440) */
@media screen and (min-width: 2560px) and (max-width: 2560px) and (max-height: 1440px) {
  .table-container {
    max-height: 350px;
  }
}

/* 22" Displays - 3K (2880x1620) */
@media screen and (min-width: 2880px) and (max-width: 2880px) and (max-height: 1620px) {
  .table-container {
    max-height: 400px;
  }
}

/* 23" Displays - 4K (3840x2160) */
@media screen and (min-width: 3840px) and (max-width: 3840px) and (max-height: 2160px) {
  .table-container {
    max-height: 500px;
  }
}

/* 25" Displays - 4K (3840x2160) */
@media screen and (min-width: 3840px) and (max-width: 3840px) and (max-height: 2160px) {
  .table-container {
    max-height: 550px;
  }
}


.table {
  margin-bottom: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Adjust the search input and button margin */
.form-control.mb-2 {
  margin-bottom: 10px;
}

.btn-success {
  margin-top: 10px;
  margin-bottom: 30px;
}
.ck-editor__editable {
  min-height: 150px; /* Default minimum height */
  max-height: 100%; /* Allow to grow indefinitely */
  resize: vertical; /* Allow vertical resizing */
  overflow: auto; /* Ensure scrollbars appear if needed */
}
/* Hiding the tooltip text for data-select and data-deselect */
.multiselect__option[data-select]::after {
  content: ''!important;
}

.multiselect__option[data-deselect]::after {
  content: ''!important;
}
.custom-tooltip-width .tooltip-inner {
  max-width: 500px;  /* Set maximum width */
  white-space: normal;  /* Allow text wrapping */
}
</style>
